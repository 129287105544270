@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,700&family=Montserrat:wght@400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* You can add global styles to this file, and also import other style files */
/* RESET CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

html, body {
    min-height: 100vh;
    height    : auto;
    margin    : 0;
}

body {
    line-height: 1;
    min-width  : 100%;
    overflow-x : hidden;
}

a {
	text-decoration: none;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing : 0;
}

body {
    margin: 0;
}

$breakpoints: ('xs-phone': 320px,
    'phone'     : 480px,
    'tablet'    : 768px,
    'desktop'   : 1024px,
    'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
    'print'    : 'print',
    'handheld' : 'handheld',
    'landscape': '(orientation: landscape)',
    'portrait' : '(orientation: portrait)',
    'retina2x' : '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
    'retina3x' : '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

mat-progress-bar .mat-progress-bar-buffer {
    background: #E4E8EB;
}


/* Global Angular Material overrides for DigitPop Sign Up and Login modals */

.cdk-overlay-dark-backdrop {
	background-size: 120% 120%; background-position: 50% 50%; background: rgba(96,96,96,0.8) radial-gradient(ellipse at center, rgba(0,0,0,0.2) 10%, rgba(0,0,0,0.6) 100%);
}

digit-pop-root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.dpop-modal, .dpop-modal * { -moz-box-sizing: border-box !important; -webkit-box-sizing: border-box !important; box-sizing: border-box !important; outline: none; }

.dpop-modal {
	//max-width: unset !important;
}

.dpop-modal .mat-dialog-container {
  overflow: visible;
  background-color: red;
	min-width: 300px;
	position: relative;
	border-radius: 16px;
	background-color: rgba(36,36,36,1);
	padding: 30px;
}

.dpop-modal .mat-dialog-content {
	padding: 0;
	margin: 0;
}

.dpop-modal .mat-dialog-close {
	width: 24px;
	height: 24px;
	text-align: center;
	line-height: 23px;
	font-size: 14px;
	position: absolute;
	top: 10px; right: 10px;
	border-radius: 50%;
	color: rgba(36,36,36,1);
	background-color: rgba(255,255,255,0.15);
	cursor: pointer;
	border: none;
	padding-bottom: 1px;
}

.dpop-modal .mat-dialog-close:hover {
	background-color: rgba(255,33,105,1);
	color: rgba(255,255,255,1);
}

.dpop-modal .form-group { position: relative; }

.dpop-modal .form-group:not(:last-child) {
	margin-bottom: 20px;
}

.dpop-modal .mat-radio-button {
	display: block;
}

.dpop-modal .mat-radio-button label {
  justify-content: center;
  text-align: center;
}

.dpop-modal .mat-radio-button:not(:last-child) {
	margin-bottom: 15px;
}

.dpop-modal .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.dpop-modal .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-35px) scale(0.85) perspective(100px) translateZ(0.001px) !important;
	-ms-transform: translateY(-35px) scale(0.85) !important;
	color: rgba(255,255,255,1);
}

.dpop-modal .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:autofill + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-35px) scale(0.85) perspective(100px) translateZ(0.001px) !important;
	-ms-transform: translateY(-35px) scale(0.85) !important;
}

.dpop-modal .alert.alert-danger.ng-star-inserted {
	padding-left: 50px;
	position: absolute; top: 45px;
	color: rgba(255,0,0,1);
	padding-top: 5px;
	font-size: 11px;
}

.dpop-modal .mat-form-field {
	display: block;
}

.dpop-modal .mat-form-field-wrapper {
	padding: 0;
}

.dpop-modal .mat-form-field-underline {
	display: none;
}

.dpop-modal .mat-form-field-infix {
	border: none;
	padding: 0;
	width: 100%;
}

.dpop-modal .mat-input-element {
	margin: 0;
	height: 45px;
	line-height: 35px;
	width: 100%;
	background-color: rgba(255,255,255,1);
	color: rgba(255,33,105,1);
	border-radius: 32px;
	padding: 5px 15px 5px 50px;
}

.dpop-modal .mat-form-field-label-wrapper {
	padding-left: 50px;
	top: -20px;
	padding-top: 0;
	height: 65px;
}

.dpop-modal .mat-form-field-label {
	left: unset;
	color: rgba(255,33,105,1);
	top: 35px;
}

.dpop-modal .mat-icon {
	position: absolute;
	color: rgba(255,33,105,1);
	z-index: 2000;
	left: 15px;
	top: 10px;
}

.dpop-modal .mat-form-field-invalid + mat-icon {
	color: rgba(255,0,0,1);
}

.dpop-modal .mat-button {
	background: rgba(255,33,105,);
	color: rgba(255,255,255,1);
	border-radius: 10px;
	width: 100%;
}
